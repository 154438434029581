export const state = () => {
  return {
    isOpen: false,
  }
}

export const mutations = {
  isOpen(state, isOpen) {
    const modalIsOpen = 'modal-is-open'

    state.isOpen = isOpen

    if (isOpen) {
      global.document.body.classList.add(modalIsOpen)
    } else {
      global.document.body.classList.remove(modalIsOpen)
    }
  },
}
