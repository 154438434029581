export const cssMenuClass = {
  isHidden: 'menu-is-hidden',
  isOpen: 'menu-is-open',
}
export class Navigation {
  constructor() {
    this.isCollapsed = true
    this.isOpen = false
    this.isAnimationInProgress = false

    // this.config = {
    //   timeout: 130,
    // }

    this.items = [
      { label: 'Home', to: '.main-header', isVisible: false },
      {
        label: 'O que é portaria virtual?',
        to: '.introduction',
        isVisible: false,
      },
      { label: 'Como funciona', to: '.showcase', isVisible: false },
      { label: 'Vantagens', to: '.feature', isVisible: false },
      { label: 'Vigilância Solidária', to: '.credibility', isVisible: false },
      { label: 'Contatos', to: '.footer', isVisible: false },
    ]
  }

  // show() {
  //   document.firstElementChild.classList.add(cssMenuClass.isOpen)
  //   return this.update()

  //   // setTimeout(() => {
  //   //   return this.update()
  //   // }, 400)
  // }

  // hide() {
  //   document.firstElementChild.classList.remove(cssMenuClass.isOpen)
  //   return this.update(false)
  // }

  // toggle() {
  //   console.log(this.isOpen)
  //   this.isOpen = true
  //   console.log(this.isOpen)
  // }

  // update(isOpen = true) {
  //   this.isOpen = isOpen
  //   // this.isCollapsed = !isOpen

  //   return Promise.resolve()

  //   // const animation = Promise.all(
  //   //   this.items.map((item, index) => {
  //   //     return new Promise((resolve, reject) => {
  //   //       this.isAnimationInProgress = true

  //   //       const timeout = setTimeout(() => {
  //   //         this.items[index].isVisible = show

  //   //         if (this.items.length - 1 === index) {
  //   //           // delay for last item
  //   //           const lastItemTimeout = setTimeout(() => {
  //   //             clearTimeout(lastItemTimeout)
  //   //             resolve()
  //   //           }, this.config.timeout * 2)
  //   //         } else {
  //   //           clearTimeout(timeout)
  //   //           resolve()
  //   //         }
  //   //       }, index * this.config.timeout)
  //   //     })
  //   //   })
  //   // )

  //   // animation.finally(() => (this.isAnimationInProgress = false))

  //   // return animation
  // }
}

// const navigation = new Navigation()

// export { navigation }
