export const breakpoints = {
  mobile: 320,
  tablet: 480,
  desktop: 768,
}

class ResponsiveModel {
  is(media, exact = false) {
    const width = screen.width

    // Mobile
    const isMobile = width < breakpoints.tablet
    const isMobileExact = isMobile

    // Tablet
    const isTablet = width >= breakpoints.tablet
    const isTabletExact =
      width > breakpoints.tablet && width <= breakpoints.desktop

    // Desktop
    const isDesktop = width >= breakpoints.desktop
    const isDesktopExact = isDesktop

    if (!media) {
      media = [
        { mobile: isMobileExact },
        { tablet: isTabletExact },
        { desktop: isDesktopExact },
      ].reduce((curr, next) => {
        const [isCurrValid] = Object.values(curr)
        return isCurrValid ? curr : next
      })

      return Object.keys(media).pop()
    }

    return {
      mobile: isMobile,
      tablet: exact ? isTabletExact : isTablet,
      desktop: exact ? isDesktopExact : isDesktop,
    }[media]
  }
}

const responsive = new ResponsiveModel()

export { responsive }
