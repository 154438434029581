import { responsive } from '@/models'
import Vue from 'vue'

Vue.mixin({
  computed: {
    // Mobile
    isMobile() {
      return responsive.is('mobile')
    },
    isMobileOnly() {
      return responsive.is('mobile', true)
    },

    // Tablet
    isTablet() {
      return responsive.is('tablet')
    },
    isTabletOnly() {
      return responsive.is('tablet', true)
    },

    // Desktop
    isDesktop() {
      return responsive.is('desktop')
    },
    isDesktopOnly() {
      return responsive.is('desktop', true)
    },

    // Utils
    viewportWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
    },

    viewportHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      )
    },
  },
})
