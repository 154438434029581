import { Navigation, cssMenuClass } from '@/models'

export const state = () => new Navigation()

export const mutations = {
  isOpen(state, isOpen) {
    state.isOpen = isOpen

    document.firstElementChild.classList[isOpen ? 'add' : 'remove'](
      cssMenuClass.isOpen
    )
  },
  isCollapsed(state, isCollapsed) {
    state.isCollapsed = isCollapsed
  },
  toggle(state, prop = 'isOpen') {
    if (prop === 'isOpen') {
      document.firstElementChild.classList[!state[prop] ? 'add' : 'remove'](
        cssMenuClass.isOpen
      )
    }
    state[prop] = !state[prop]
  },
}
