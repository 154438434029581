export const state = () => {
  return {
    isVisible: false,
  }
}

export const mutations = {
  isVisible(state, isVisible) {
    state.isVisible = isVisible
  },
}
