import { throttle } from '@/helpers'
import Vue from 'vue'

Vue.mixin({
  mounted() {
    global.addEventListener('scroll', throttle(this.onScroll, 50))
    global.addEventListener('resize', throttle(this.onResize, 50))
    global.addEventListener('keyup', this.onKeyup)

    global.addEventListener('fullscreenchange', this.onChangeFullscreen)
    global.addEventListener('webkitfullscreenchange', this.onChangeFullscreen)
    global.addEventListener('mozfullscreenchange', this.onChangeFullscreen)
    global.addEventListener('MSFullscreenChange', this.onChangeFullscreen)
  },
  methods: {
    onScroll() {},
    onResize() {},
    onKeyup() {},
    onChangeFullscreen() {},
  },
})
