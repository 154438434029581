export function goFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    /* Firefox */
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    element.webkitRequestFullscreen()
  } else if (element.msRequestFullscreen) {
    /* IE/Edge */
    element.msRequestFullscreen()
  }
}

export function isFullscreen() {
  return document.fullscreen
}
